.form-block.flat {
    .form-block-holder { 
        padding: 0;
    }
}
.form-block.col-with-even-offset {
    .form-block-holder { 
        form {
            .form-group {
                width: calc(100% + 12px);
                .form-col {
                    &.col-33-evevn {
                        width: calc(33% - 146px/3);
                    }
                    &.col-25-evevn {
                        width: calc(25% - 146px/4);
                    }
                    &.col-offset-even {
                        width: 146px;
                    }
                }
            }
        }
    }
}
.form-block.col-with-even-offset-2 {
    .form-block-holder { 
        form {
            .form-group {
                width: calc(100% + 1px);
                .form-col {
                    &.col-33-evevn {
                        width: calc(33% - 90px/3);
                    }
                    &.col-25-evevn {
                        width: calc(25% - 90px/4);
                    }
                    &.col-offset-even {
                        width: 90px;
                    }
                }
            }
        }
    }
}
.form-block.imbeded {
    margin: 0;
}
.form-block { 
    display: inline-block;
    width: 100%;
    margin: 0 0 133px;
	.form-block-holder { 
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        padding: 0 11px 0 171px;
        .heading { 
            display: inline-block;
            width: 100%;
            margin: 0 0 62px;
            .h1 { 
                text-transform: uppercase;
                margin: 0;
            }
        }

    }
}