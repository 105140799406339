.breadcrumbs {
    display: inline-block;
    width: 100%;
    margin-top: 21px;
    margin-bottom: 19px;
    .breadcrumbs-holder {
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        ul.breadcrumbs-list {
            display: inline-block;
            width: 100%;
            li {
                display: inline;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 28px;
                margin-right: 5px;
                color: #959C9F;
                a {
                    color: $brand-primary;
                    text-decoration: none;
                    transition: all 300ms;
                    &:hover {
                        color: $brand-primary-accent;
                        transition: all 300ms;
                    }
                }
                span {

                }
                &.sep {

                }
            }
        }
    }
}

// @import "../media/tablet/includes/breadcrumbs";
// @import "../media/mobile/includes/breadcrumbs";
