.gallery { 
    display: inline-block;
    width: 100%;
    margin: 0 0 132px;
	.gallery-holder { 
        display: inline-block;
        width: 100%;
        .gallery-slider { 
            display: inline-block;
            width: 100%;
            .gallery-slider-holder { 

                .image { 
                    display: inline-block;
                    width: 100%;
                    height: 480px;
                    .image-holder { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        a { 
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                            overflow: hidden;
                            &:hover {
                                img {
                                    transform: scale(1.1);
                                    transition: all 300ms;
                                }
                            }
                            img { 
                                display: inline-block;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                transition: all 300ms;
                            }
                        }
                    }
                }
            }
        }
    }
}