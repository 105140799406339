.personel-block { 
    display: inline-block;
    width: 100%;
    margin: 99px 0 81px;
	.personel-block-holder { 
        display: inline-block;
        width: 100%;
        .peronsel-details-list { 
            float: left;
            width: calc(100% - 285px);
            .peronsel-details-list-holder { 
                display: inline-block;
                width: 100%;
                .personel-list { 
                    display: inline-block;
                    width: 100%;
                    .personel-list-holder { 
                        display: flex;
                        flex-wrap: wrap;
                        width: calc(100% + 50px);
                        margin-left: -25px;
                        .personel-item { 
                            float: left;
                            width: 50%;
                            box-sizing: border-box;
                            padding: 0 25px;
                            .personel-item-holder { 
                                display: inline-block;
                                width: 100%;
                                .info { 
                                    display: inline-block;
                                    width: 100%;
                                    margin: 0 0 30px;
                                    .info-holder { 
                                        display: flex;
                                        width: calc(100% + 24px);
                                        margin-left: -12px;
                                        .image { 
                                            width: 224px;
                                            height: 220px;
                                            box-sizing: border-box;
                                            padding: 0 12px;
                                            .image-holder { 
                                                display: inline-block;
                                                width: 100%;
                                                height: 100%;
                                                img { 
                                                    display: inline-block;
                                                    width: 100%;
                                                    height: 100%;
                                                    object-fit: cover;
                                                }
                                            }
                                        }

                                        .name-block { 
                                            width: calc(100% - 224px);
                                            height: auto;
                                            box-sizing: border-box;
                                            padding: 0 12px;
                                            .name-block-holder { 
                                                display: flex;
                                                flex-direction: column;
                                                justify-content: flex-end;
                                                width: 100%;
                                                height: 100%;
                                                .name { 
                                                    font-style: normal;
                                                    font-weight: 700;
                                                    font-size: 18px;
                                                    line-height: 120%;
                                                    margin: 0 0 9px;
                                                }

                                                .position { 
                                                    font-style: normal;
                                                    font-weight: 500;
                                                    font-size: 14px;
                                                    line-height: 120%;
                                                }
                                            }
                                        }
                                    }
                                }

                                .desc { 
                                    display: inline-block;
                                    width: 100%;
                                    .desc-holder { 
                                        display: inline-block;
                                        width: 100%;
                                        .text { 
                                            display: inline-block;
                                            width: 100%;
                                            p { 

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .personel-list-col { 
            float: left;
            width: 285px;
            .personel-list-col-holder { 
                display: flex;
                justify-content: flex-end;
                .personel-plain-list { 
                    min-width: 190px;
                    .personel-plain-list-holder { 
                        .personel-group { 
                            margin:  0 0 27px;
                            .heading { 
                                font-style: normal;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 120%;
                                margin:  0 0 8px;

                            }

                            .peronsel-names { 
                                .name { 
                                    a { 
                                        font-style: normal;
                                        font-weight: 700;
                                        font-size: 18px;
                                        line-height: 120%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}