.project-detail { 
    display: inline-block;
    width: 100%;
    margin: 0 0 71px;
	.project-detail-holder { 
        display: flex;
        width: 100%;
        height: 93.5vh;
        position: relative;
        .project-description { 
            float: left;
            width: 43.1%;
            .project-description-holder { 
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                padding: 40px 0 0;

                .contains.type-2 {
                    .project-heading { 
                        margin: 0 0 38px;
                        .project-heading-holder { 
                            position: relative;
                            box-sizing: border-box;
                            padding-left: 22.5px;
                            &:before {
                                content: "";
                                display: inline-block;
                                width: 6.5px;
                                height: 100%;
                                background: currentColor;
                                position: absolute;
                                left: 0;
                                top: 0;
                            }
                            h2, .h2 { 
                                padding-left: 0;
                                margin: 0;
                                &:before {
                                    display: none;
                                }
                            }

                            .category { 
                                padding-left: 0;
                                margin: 0 0 8px;
                            }
                        }
                    }

                    .tasks-completed { 
                        box-sizing: border-box;
                        padding-left: 22.5px;
                        .tasks-completed-holder { 
                            .task { 
                                margin: 0;
                            }
                        }
                    }

                    .project-stats {
                        box-sizing: border-box;
                        padding-left: 22.5px;
                    }
                }

                .contains {
                    display: inline-block;
                    width: 100%;
                    flex-grow: 1;
                    .project-heading { 
                        display: inline-block;
                        width: 100%;
                        margin: 0 0 22px;
                        .project-heading-holder { 
                            display: inline-block;
                            width: 100%;
                            h2, .h2 { 
                                text-transform: uppercase;
                                line-height: 120%;
                                position: relative;
                                box-sizing: border-box;
                                padding-left: 22.5px;
                                &:before {
                                    content: "";
                                    display: inline-block;
                                    width: 6.5px;
                                    height: 100%;
                                    background: currentColor;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                }
                            }
    
                            .category { 
                                display: inline-block;
                                width: 100%;
                                box-sizing: border-box;
                                padding: 0 0 0 22px;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 120%;
                                text-transform: uppercase;
                            }
                        }
                    }
    
                    .image { 
                        display: inline-block;
                        width: 100%;
                        margin: 0 0 34px;
                        .image-holder { 
                            display: inline-block;
                            width: 100%;
                            img { 
                                max-width: 100%;
                            }
                        }
                    }
    
                    .tasks-completed { 
                        display: inline-block;
                        width: 100%;
                        margin: 0 0 38px;
                        .tasks-completed-holder { 
                            display: inline-block;
                            width: 100%;
                            .heading { 
                                display: inline-block;
                                width: 100%;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 120%;
                                text-transform: uppercase;
                                margin: 0 0 8px;
                            }
    
                            .task { 
                                display: inline-block;
                                width: 100%;
                                font-style: normal;
                                font-weight: 700;
                                font-size: 18px;
                                line-height: 120%;
                                margin: 0 0 5px;
                            }
                        }
                    }
                }
                

                .scroll { 
                    display: inline-block;
                    width: 100%;
                    padding: 0 0 15px;
                    a { 
                        display: inline-block;
                        margin-left: -8px;
                    }
                }
            }
        }

        .project-main-image { 
            position: absolute;
            left: 43.1%;
            top: -60px;
            height: calc(100% + 60px);
            .project-main-image-holder { 
                height: 100%;
                img { 
                    height: 100%;
                }
            }
        }
    }
}