.gallery-big { 
    display: inline-block;
    width: 100%;
    margin: 0 0 120px;
	.gallery-big-holder { 
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 20px);
        margin-left: -10px;
        .gallery-item { 
            display: inline-block;
            width: 100%;
            height: auto;
            box-sizing: border-box;
            padding: 0 10px;
            margin: 0 0 20px;
            &.item-50 {
                width: 50%;
            }
            &.item-33 {
                width: 33.333333%;
            }
            &.item-66 {
                width: 66.666666%;
            }
            .gallery-item-holder { 
                display: inline-block;
                width: 100%;
                height: 100%;
                a { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    &:hover {
                        img {
                            transform: scale(1.1);
                            transition: all 300ms;
                        }
                    }
                    img { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: all 300ms;
                    }
                }
            }
        }
    }
}