.contacts { 
    display: inline-block;
    width: 100%;
    margin: 98px 0 97px;
	.contacts-holder { 
        display: inline-block;
        width: 100%;
        .contacts-info { 
            display: inline-block;
            width: 100%;
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 100%;
            text-transform: uppercase;
            margin: 0 0 37px;
            .phone { 
                float: left;
                width: 299px;
                a { 

                }
            }

            .address { 
                float: left;
                br { 

                }
            }
        }

        .map { 
            display: inline-block;
            width: 100%;
            height: 293px;
            .map-holder { 
                display: inline-block;
                width: 100%;
                height: 100%;
                position: relative;
                background: rgba(57, 68, 79, 0.05);
                .container {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    mix-blend-mode: multiply;
                    filter: saturate(0);
                    img { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

            }
        }
    }
}

.contacts-form-block { 
    display: inline-block;
    width: 100%;
    margin: 0 0 138px;
	.contacts-form-holder-block { 
        display: flex;
        width: 100%;
        .form-container { 
            float: left;
            width: 700px;
            height: auto;
            .form-container-holder { 
                display: inline-block;
                width: 100%;
            }
        }

        .right-block { 
            float: left;
            width: calc(100% - 700px);
            height: auto;
            .right-block-holder { 
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                padding: 0 4px 40px 0;
                .tender { 

                    .email { 
                        font-style: normal;
                        font-weight: 500;
                        font-size: 32px;
                        line-height: 140%;
                        letter-spacing: 0.008em;
                        margin: 0 0 27px;
                        a { 

                        }
                    }

                    .action { 
                        font-style: italic;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 140%;
                        letter-spacing: 0.008em;
                        text-transform: uppercase;
                        a { 

                        }
                    }
                }
            }
        }
    }
}