.project-detail-big { 
    display: inline-block;
    width: 100%;
    height: 725px;
    margin: 24px 0 37px;
	.project-detail-big-holder { 
        display: inline-block;
        width: 100%;
        height: 100%;
        position: relative;
		.project-main-image { 
            display: inline-block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
			.project-main-image-holder { 
                display: inline-block;
                width: 100%;
                height: 100%;
				img { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
				}
			}
		}

        .project-detail-big-contains { 
            display: inline-block;
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 2;
            .wrapper-inside {
                height: 100%;
            }
            .project-detail-big-contains-holder { 
                display: flex;
                align-items: flex-end;
                width: 100%;
                height: 100%;
                .project-description { 
                    display: inline-block;
                    background: white;
                    padding: 20px 40px 16px;
                    margin-left: -40px;
                    min-width: 530px;
                    .project-description-holder {
                        
                        .project-name { 
                            position: relative;
                            padding-left: 22.5px;
                            &:before {
                                content: "";
                                width: 6.5px;
                                height: 100%;
                                background-color: black;
                                position: absolute;
                                left: 0;
                                top: 0;
                            }
                            h1, .h1 { 
                                font-style: normal;
                                font-weight: 400;
                                font-size: 32px;
                                line-height: 100%;
                                text-transform: uppercase;
                                margin: 0;
                            }
                        }
    
                        .note { 
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 120%;
                            text-transform: uppercase;
                            margin: 18px 0 0;
                        }
                    }
                }
            }
        }
	}
	
}