.project-stats.inline-stats {
    .project-stats-holder { 
        .stats {
            height: auto;
        }
    }
}
.project-stats.two-col {
    .project-stats-holder { 
        .stats {
            column-count: 2;
            column-gap: 76px;
        }
    }
}
.project-stats { 
    display: inline-block;
    width: 100%;
    margin: 0 0 32px;
	.project-stats-holder { 
        display: inline-block;
        width: 100%;
        .stats { 
            display: inline-block;
            width: 100%;
            column-count: 4;
            height: 155px;
            column-gap: 40px;
            .stat { 
                display: inline-block;
                width: 100%;
                margin: 0 0 19px;
                .stat-holder { 
                    display: inline-block;
                    width: 100%;
                    .name { 
                        display: inline-block;
                        width: 100%;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 120%;
                        text-transform: uppercase;
                        margin: 0 0 4px;
                    }

                    .value { 
                        display: inline-block;
                        width: 100%;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 120%;
                    }
                }
            }
        }
    }
}