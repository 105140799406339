.vacancies { 
    display: inline-block;
    width: 100%;
    margin: 160px 0 105px;
	.vacancies-holder { 
        display: inline-block;
        width: 100%;
        .vacancy { 
            display: inline-block;
            width: 100%;
            border-bottom: 0.5px solid #000000;
            border-top: 0.5px solid transparent;
            &:first-of-type {
                border-top: 0.5px solid #000000;
            }
            .vacancy-holder { 
                display: inline-block;
                width: 100%;
                padding: 18px 0 0;
                .vacancy-description { 
                    float: left;
                    width: calc(100% - 150px);
                    .vacancy-description-holder {
                        display: inline-block;
                        width: 100%;
                        min-height: 245px;
                        .name { 
                            display: inline-block;
                            width: 100%;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 120%;
                            margin: 0 0 8px;
                        }
    
                        .tags { 
                            display: inline-block;
                            width: 100%;
                            margin-left: -16px;
                            margin-bottom: 20px;
                            .tag { 
                                font-style: normal;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 120%;
                                float: left;
                                margin: 0 16px;
                            }
                        }
    
                        .text { 
                            display: inline-block;
                            width: 100%;
                            .dots { 
    
                                li { 
    
                                }
                            }
                        }
                    }
                }

                .action { 
                    float: left;
                    width: 150px;
                    .action-holder { 
                        display: inline-block;
                        width: 100%;
                        text-align: right;
                        a { 
                            font-style: italic;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 140%;
                            letter-spacing: 0.008em;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
}